import React, { useState } from 'react';
import { sendContactRequest } from '../../api/axios';

export default function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        if (Object.values(formData).some(x => x === '')) {
            alert('Please enter all credentials');
        } else {
            await sendContactRequest(formData.name, formData.company, formData.email, formData.phone, formData.message)
              .then(data => {
                console.log('Server response:', data);
              })
              .catch(error => {
                console.error('Failed to send contact information:', error);
              });

            // // Fetch call to send formData
            // console.log("Sending Data:", formData);
            // const apiUrl = 'https://api.emailjs.com/api/v1.0/email/send'; // Replace with the actual API endpoint
            // const credentials = {service_id: 'service_ta6lx0k',template_id: 'template_h0pcz9w',user_id: '8mJgKdWP4KMilGRz_', template_params: formData}
            // console.log("credentials"+JSON.stringify(credentials))
            // fetch(apiUrl, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify(credentials)
            // })
            // .then(response => {
            //     if (response.ok) {
            //     return response.json();
            //     console.log(response)
            //     console.log("response:"+response['response'])
            //     document.getElementById('answer').innerHTML = response['response'];
            //     }
            //     throw new Error('Network response was not ok.');
            // })
            // .then(responseData => {
            //     console.log('Response from server:', responseData);
            //     console.log("response:"+responseData['response'])
            //     document.getElementById('answer').innerHTML = responseData['response'];
            //     // Handle the response data here
            // })
            // .catch(error => {
            //     console.error('There was a problem with the POST request:', error);
            // });

            const sendBtn = document.getElementById("sendBtn");
            sendBtn.setAttribute('disabled', 'true');
        }
    };

    return (
        <main className="relative w-full flex pt-10 pb-10  h-[100vhvh] md:h-[110] flex-col items-center justify-center">
            {/* <div id="contact" className="relative w-full"> */}
            <img src='/images/websiteBg.jpg' className='object-cover h-full  z-1 absolute top-0 w-full left-0'/>

                <span className="bg-green relative text-white p-2 rounded">Get In Touch</span>
                <h1 className="mb-5 md:mb-7 relative">Contact</h1>
                <div className="flex justify-center py-0 relative  gap-20 md:gap-20 w-[95%] md:w-[600px]">
                    <form className="w-[90%]" onSubmit={handleSubmit}>
                        <input className="px-2" type='text' name="name" placeholder="Full Name" required value={formData.name} onChange={handleChange} />
                        <input className="px-2" type='text' name="company" placeholder="Company/Organization" required value={formData.company} onChange={handleChange} />
                        <input className="px-2" type='email' name="email" placeholder="Email" required value={formData.email} onChange={handleChange} />
                        <input className="px-2" type='text' name="phone" placeholder="Phone" required value={formData.phone} onChange={handleChange} />
                        <textarea className="px-2" name="message" rows={7} placeholder="Your Message" value={formData.message} onChange={handleChange} />
                        <button id='sendBtn' type="submit" className="w-full  cursor-pointer bg-green hover:scale-105 transition ease-in-out text-white py-2 px-4 rounded hover:bg-blue-600 text-[25px] md:text-[30px]">Send</button>
                    </form>

                    {/* <svg className="h-full opacity-70 md:block hidden" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.57 51.34" style={{fill: '#184B29'}}>
                        <g id="Layer_1-2" data-name="Layer 1" className="col-green">
                            <path d="m26.56,0C34.07,0,41.57,0,49.08,0c2.12,0,3.5,1.37,3.5,3.48,0,14.77,0,29.55,0,44.32,0,2.13-1.36,3.52-3.46,3.52-14.93,0-29.86,0-44.79,0-1.32,0-2.62-.09-3.45-1.24-.44-.61-.84-1.42-.84-2.14C-.01,33.11,0,18.26,0,3.4,0,1.7,1.28.29,2.98.08,3.49.01,4.01,0,4.53,0,11.88,0,19.22,0,26.56,0ZM5.97,9.97c-.27,1.5-.53,2.77-.73,4.06-.94,6-1.5,12.01-.35,18.06,1.07,5.61,3.32,10.55,8.11,14.01,4.57,3.3,9.81,4.47,15.35,4.59,1.67.03,1.98-.2,2.49-1.81,1.03-3.28,1.08-6.6.12-9.89-1.97-6.75-6.52-11.16-12.89-13.86-.36-.15-.69-.35-1.04-.52.06-.11.12-.23.17-.34,5.44.79,10.64,2.22,14.7,6.24,4.17,4.14,5.2,9.46,5.3,15.11.31-.43.49-.85.64-1.28.86-2.65,1.13-5.37.98-8.13-.28-5.13-2.24-9.49-6.23-12.81-3.49-2.9-7.66-4.56-11.79-6.25-5.01-2.05-10.24-3.62-14.81-7.17ZM39.75.89c-1.65,1.19-3.11,2.2-4.53,3.26-4.24,3.17-8.13,6.68-11.26,10.98-.77,1.05-.72,1.21.45,1.72.36.16.73.32,1.1.47,5.45,2.3,10.4,5.25,13.76,10.32.04.06.2.05.69.15-.89-2.77-1.72-5.33-2.49-7.72.33.48.77,1.12,1.21,1.76.09-.05.19-.1.28-.15-.37-.86-.75-1.72-1.12-2.57.09-.05.17-.09.26-.14.87,1.36,1.8,2.69,2.6,4.09,3.88,6.74,4.48,13.47.05,20.27-.72,1.1-.95,2.52-1.41,3.79.11.06.21.12.32.18.49-.33,1-.62,1.46-.98,6.24-4.93,9.4-11.18,7.41-19.14-1.02-4.1-2.81-8.02-4.27-12.01-1.36-3.73-2.79-7.44-4.11-11.18-.3-.86-.26-1.85-.41-3.07Z"/>
                        </g>
                    </svg> */}
                </div>
            {/* </div> */}
        </main>
    );
}