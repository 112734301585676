import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";


const Certifications = () => {


    return(
        <section className="bg-white h-[100vh]">
            <span className="bg-green text-white p-2 rounded">What we provide</span>
            <h1 className="mb-10">Certifications</h1>
            <div className="flex md:flex-row flex-col gap-10 m-auto justify-center">
                <div className=" md:w-1/3 w-[70%] m-auto">
                    <img src="/images/leed_logo.png" className="m-auto h-1/2 pb-2"/>
                    {/* <h2 className="text-[70px]">Leed Certification</h2> */}
                    {/* <button className="flex items-center gap-5 justify-between bg-green w-full text-white text-[50px] px-20 py-3 uppercase">What is Leed? <FaExternalLinkAlt className="text-[75px]"/>                    </button> */}

                </div>
                <div className=" md:w-1/3 w-[70%] m-auto">
                    <img src="/images/breeam_logo.png" className="m-auto h-1/2"/>
                    {/* <h2 className="text-[70px]">BREEAM Certification</h2> */}
                    {/* <button className="flex items-center gap-5 justify-between bg-green w-full text-white text-[50px] px-20 py-3 uppercase">What is Breeam? <FaExternalLinkAlt className="text-[75px]"/>                    </button> */}
                </div>
            </div>
        </section>
    )
}

export default Certifications;