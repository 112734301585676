import React from "react";

export default function Clients() {
    return(
<main id="clients" className="story-section md:px-[40px] px-[20px] md:py-[80xp] py-[60px] w-full">
    <span className="bg-green text-white p-2 rounded">Who We Work With</span>
    <h1 className="mb-10">Our Clients</h1>
    <div className="client w flex justify-evenly h-min md:flex-row flex-col md:gap-[0] gap-[30px]">
        {/* First Client */}
        <div className="client-container md:w-2/5 w-full flex flex-col">
            <p className="md:text-[30px] text-[20px] client-description bg-green/80 text-white p-[17px] md:p2 rounded ">Since 2019</p>
            <div className=" py-10 px-2 shadow-2xl flex items-center">
                <img src="/images/climate.png" className="m-auto " />
            </div>
            <div className="client-description bg-green text-white p-[17px] md:p2 rounded opacity-80 flex-grow">
                {/* <p className="md:text-[30px] text-[20px]">Since 2017</p> */}
                <p className="text-justify p-2 text-[20px] md:text-[25px]">
                We assess planning applications across Greater London, ensuring compliance with the London Plan, building regulations, and sustainability measures, focusing on carbon targets, energy efficiency, Net Zero strategies, and BREEAM credentials.
                </p>
            </div>
        </div>

        {/* Second Client */}
        <div className="client-container md:w-2/5 w-full flex flex-col shadow-lg">
        <p className="md:text-[30px] text-[20px] client-description bg-black/80 text-white p-[17px] md:p2 rounded ">Since 2017</p>

            <div className=" py-10 px-2 shadow-2xl flex items-center">
                <img src="/images/BWB_transparent.png" className="m-auto " />
            </div>
            <div className="client-description bg-black text-white p-[17px] md:p2 rounded opacity-80 flex-grow">
                {/* <p className="md:text-[30px] text-[20px]">Since 2021</p> */}
                <p className="text-justify p-2 text-[20px] md:text-[25px]">
                    Carrying out BREEAM assessments on complex projects including Industrial Warehouses, Offices, Fire Stations, Education Buildings (primary schools, higher & further education), Healthcare, Leisure Centres, Care homes, and Multi-residential developments.
                </p>
            </div>
        </div>
    </div>
</main>

    )
}