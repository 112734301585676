import logo from './logo.svg';
import './App.css';
import Landing from './components/Landing';
import NavBar from './components/NavBar';
import Projects from './components/Projects';
import Clients from './components/Clients';
import Qualifications from './components/Qualifications';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Technology from './components/Technology';
import Form from './components/Form';
import Certifications from './components/Certifications';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Breeam from './pages/Breeam';
import { ModalProvider } from './context/ModalContext';

function App() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    // alert(sectionId)
    if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }


  return (
    <ModalProvider>
    <BrowserRouter>
    
    
      <Routes>
        <Route path='/' element={<Home/>}/>
        {/* <Route path='/breeam' element={<Breeam/>}/>
        <Route path='/leed' element={<Home/>}/> */}
      </Routes>
    </BrowserRouter>

    </ModalProvider>
  );
}

export default App;
