import React from "react";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

import { FaUser } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { SlCalender } from "react-icons/sl";

import "swiper/css";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";
import { useModal } from "../../context/ModalContext";

const ProjectSwiper = (props) => {
    const {openModal} = useModal();
    const content = props.content;
    const setShowModal = props.setShowModal;
    return(
        <section className="swiper-section  select-none">
          <Swiper
            slidesPerView={2.6}
            spaceBetween={20}
            navigation={true}
            grabCursor={true}
            breakpoints={{
              320: {
                slidesPerView: 1.6,
              },
              998: {
                slidesPerView: 2.6,
              },
            }}
            // autoplay={{
            //   delay: 2500,
            //   disableOnInteraction: false,
            // }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            {/* <SwiperBtn /> */}
            {content.map((element, index) => {
              return (
                <SwiperSlide key={index} className="my-5">
                  <div className="story_card flex shadow-xl h-[150px] md:h-[300px]">
                    <img
                      src={element.img}
                      className="lg:max-w-[500px] w-[38%] max-w-[3000px] h-[150px] md:h-[300px] object-cover"
                      alt=""
                    />
                    <div className="relative w-full h-full  bg-white py-3 px-2 md:px-5">
                      <h4 className="font-primary text-left text-[18px] md:text-[30px] text-black font-medium md:mb-3 uppercase ">
                        {element.title}
                      </h4>
                      <p className="text-justify text-gray leading-5 text-[16px] mb-4 ">
                        {/* {element.content} */}
                      </p>
                      <div className="hidden text-left md:flex flex-col gap-2 text-[10px] md:text-[15px]">
                      <span className="flex gap-1 items-center"><FaUser className="text-[19px]"/>{element.client}</span>
                      <span className="flex gap-1 items-center"><FaLocationDot className="text-[19px]"/>{element.location}</span>
                      <span className="flex gap-1 items-center"><SlCalender className="text-[19px] font-bold"/>{element.date}</span>
                      </div>
                      <button onClick={() => {setShowModal(element); openModal()}} className="md:absolute bottom-5 left-5  btn-primary bg-green md:w-[90%] rounded text-white text-[15px] md:text-[20px]  p-3 ">
                        Learn More{" "}
                        {/* <MdOutlineKeyboardDoubleArrowRight className="ms-1 text-[20px]" /> */}
                      </button>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </section>
    )
}

export default ProjectSwiper;