import React, { useEffect, useState } from "react";
// import './Projects.css'

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

import { FaUser } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { SlCalender } from "react-icons/sl";
import { IoStarSharp } from "react-icons/io5";

import "swiper/css";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";
import ProjectSwiper from "./ProjectSwiper";
import { useModal } from "../../context/ModalContext";

export default function Projects() {

  const {isModalOpen, closeModal} = useModal();



  const [showModal, setShowModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(    {
    img: "/images/proj1_unitb.png",
    title: "Unity Connect Unit B",
    client: "Waystone Hargreaves Land LLP",
    location: "Doncaster, South Yorkshire",
    date: "December 2023",
    rating: "Very Good",
    area: 2645,
    type: "Industrial",
    scope: "Fully Fitted",
    content:
      "The development proposal includes the construction of a new 'Shell & Core' Industrial/Warehouse unit with ancillary offices, external yard, car park and service access."
  });

  const ratingsDictionary = {
    "Good": 3,
    "Very Good": 4,
    "Excellent": 5,
    "Outstanding": 6
  };

  const playerContent = [
    {
      img: "/images/proj1_unitb.png",
      title: "Unity Connect Unit B",
      client: "Waystone Hargreaves Land LLP",
      location: "Doncaster, South Yorkshire",
      date: "December 2023",
      rating: "Very Good",
      area: 2645,
      type: "Industrial",
      scope: "Fully Fitted",
      content:
        "The development proposal includes the construction of a new 'Shell & Core' Industrial/Warehouse unit with ancillary offices, external yard, car park and service access."
    },
    {
      img: "/images/proj2_unit2.png",
      title: "White Hart Lane Works",
      client: "LaSalle Investment Management",
      location: "North London",
      date: "October 2021",
      rating: "Excellent",
      area: 2677,
      type: "Industrial",
      scope: "Shell & Core",
      content:
        "The project is for the replacement of an industrial unit within the White Hart Lane Park that was damaged following a fire in November 2019. The development is a ‘Shell and Core’ - Warehouse with associated offices, external delivery yards and car-parking."
    },
    {
      img: "/images/proj3_heathlands.png",
      title: "Heathlands care home",
      client: "Bracknell Forest Council",
      location: "Crossfell, Bracknell",
      date: "November 2021",
      rating: "Excellent",
      area: 2677,
      type: "Residential",
      scope: "Fully Fitted",
      content:
        "The construction of 66 bed Care Home for Dementia including an intermediate unit (20 beds) in Bracknell."
    },
    {
      img: "/images/proj4_bingley.png",
      title: "Bingley Street",
      client: "Gregory Projects",
      location: "Leeds",
      date: "2020",
      rating: "Excellent",
      area: 2677,
      type: "Industrial",
      scope: "Fully Fitted",
      content:
        "To construct new 407 bed living student accommodation located on Bingley Street - in two blocks."
    },
    {
      img: "/images/proj5_kingsway.png",
      title: "Kingsway Business Park",
      client: "Wilson Bowden developments",
      location: "Greater Manchester",
      date: "September 2023",
      rating: "Very Good",
      area: 2677,
      type: "Industrial",
      scope: "Shell & Core",
      content: ""
    },
    {
      img: "/images/proj7_childcenter.jpg",
      title: "Child Development Centre",
      client: "NHS Crawley",
      location: "Crawley",
      date: "September 2023",
      rating: "Very Good",
      area: 2677,
      type: "Healthcare",
      scope: "Fully Fitted",
      content:
        "Part refurbishment of Yellow Wing to form Child Development Centre (CDC)"
    },
    {
      img: "/images/proj10_artsfacility.jpg",
      title: "Arts Faculty Building",
      client: "NHS Crawley",
      location: "University of Nottingham",
      date: "2014",
      rating: "Excellent",
      area: 2677,
      type: "Education",
      scope: "Fully Fitted",
      content: ""
    },
    {
      img: "/images/proj11_standish.jpg",
      title: "Standish Medical Centre",
      client: "NHS Crawley",
      location: "Lancashire",
      date: "2008",
      rating: "Excellent",
      area: 2677,
      type: "Healthcare",
      scope: "Fully Fitted",
      content: ""
    }
  ];

  useEffect(() => {
    setShowModal(true);
  }, [selectedProject])

  // if (!isModalOpen) {
  //   return null;
  // }

  return (
    <>
      <main className="story-section md:px-[40px] px-[20px] md:py-[80px] py-[60px] w-full" id="projects">
        <span className="bg-green text-white p-2 rounded">Stories of Success</span>
        <h1>Our Projects</h1>

        <h1 className="text-left w-full font-normal translate-y-[2px] mt-10">Industrial</h1>
        <div className="h-[4px] w-full bg-green" />
        <ProjectSwiper setShowModal={setSelectedProject}  content={playerContent.filter(obj => obj.type === "Industrial")} />

        <h1 className="text-left w-full font-normal translate-y-[2px] mt-10">Healthcare</h1>
        <div className="h-[4px] w-full bg-green" />
        <ProjectSwiper setShowModal={setSelectedProject} content={playerContent.filter(obj => obj.type === "Healthcare")} />

        <h1 className="text-left w-full font-normal translate-y-[2px] mt-10">Residential</h1>
        <div className="h-[4px] w-full bg-green" />
        <ProjectSwiper setShowModal={setSelectedProject} content={playerContent.filter(obj => obj.type === "Residential")} />

        <h1 className="text-left w-full font-normal translate-y-[2px] mt-10">Education</h1>
        <div className="h-[4px] w-full bg-green" />
        <ProjectSwiper setShowModal={setSelectedProject} content={playerContent.filter(obj => obj.type === "Education")} />
      </main>



      {isModalOpen && (

        <div className="z-50 w-full fixed flex flex-col h-[100vh] top-0 bg-black/50">
          <div className="bg-white h-[90vh] flex m-auto flex-col  w-[90%] h-[90%]  relative">
            {/* Modal Header */}
            <div className="modal-header z-20 flex  items-center px-10 py-10 relative text-white uppercase justify-between">
              <button
                className="absolute top-[5px] bg-black/25 shadow-xl roundedd-full w-8 right-[5px] z-50 text-[20px] font-bold hover:cursor-pointer hover:scale-110"
                onClick={() => closeModal()}
              >
                &times;
              </button>
              <img src="/images/websiteBg.jpg" className="object-cover object-top text-left h-full z-10 absolute top-0 w-full left-0" alt="background" />
              <span>
                <h1 className="z-20 relative font-semibold text-[50px]">{selectedProject.title}</h1>
                <h2 className="z-20 relative font-semibold text-left text-[35px]">{selectedProject.type}</h2>

              </span>
              <div className="flex flex-row items-center justify-center gap-3 z-10 relative backdrop-blur-[1px] shadow-lg bg-white/10 py-3 px-2 rounded-xl ">
                  <img src="/images/breeam_logo.png" className="m-auto w-[110px] " />
                  <div>

                    <h2>{selectedProject.rating}</h2>
                    <div className="flex gap-1 w-min m-auto">
                      {Array.from({ length: ratingsDictionary[selectedProject.rating] }, (_, index) => (
                        <IoStarSharp key={index} className="text-yellow-500" />
                      ))}
                    </div>
                  </div>

                </div>
            </div>


              <div className="w-full  relative overflow-none flex justify-center ">
                {/* <img src={selectedProject.img} className="w-full object-cover  absolute z-0 h-full" alt={selectedProject.title} /> */}

              </div>
              <div className="  justify-between flex-grow  flex py-5 px-10 flex-row gap-2 text-[10px] md:text-[25px]">
              {/* <button
                className="absolute top-4 right-5 z-50 text-[30px] font-bold hover:cursor-pointer hover:scale-110"
                onClick={() => setShowModal(false)}
              >
                &times;
              </button> */}
              <section className="pr-7 ">
                <table className="grid grid-cols-2 md:gap-5 text-[15px] gap-2">
                <div>
                  <h1 className="text-[16px] text-left font-semibold">Construction Client:</h1>
                  {/* <div className="h-[1px] w-full bg-black"/> */}
                  <span className="flex gap-2 items-center">
                    {/* <FaUser />  */}
                    <h2 className="-translate-y-1">{selectedProject.client}</h2>
                  </span>

                </div>

                
                
                <span className="flex flex-col  text-left">
                <h1 className="text-[16px] text-left font-semibold">Location:</h1>
                {/* <FaLocationDot /> */}
                   <h2 className="-translate-y-1">{selectedProject.location}</h2>
                </span>

                <div>
                  <h1 className="text-[16px] text-left font-semibold">Scope:</h1>
                  {/* <div className="h-[1px] w-full bg-black"/> */}
                  <span className="flex gap-2 items-center">
                    {/* <FaUser />  */}
                    <h2 className="-translate-y-1">{selectedProject.scope}</h2>
                  </span>

                </div>
                <div>
                  <h1 className="text-[16px] text-left font-semibold">Completed:</h1>
                  {/* <div className="h-[1px] w-full bg-black"/> */}
                  <span className="flex gap-2 items-center">
                    {/* <FaUser />  */}
                    <h2 className="-translate-y-1">{selectedProject.date}</h2>
                  </span>

                </div>

                <div>
                  <h1 className="text-[16px] text-left font-semibold">Area:</h1>
                  {/* <div className="h-[1px] w-full bg-black"/> */}
                  <span className="flex gap-2 items-center">
                    {/* <FaUser />  */}
                    <h2 className="-translate-y-1">{selectedProject.area}m²</h2>
                  </span>

                </div>


                {/* <span className="flex gap-2 items-center">
                  <SlCalender className="font-bold" /> {selectedProject.date}
                </span>
                <span className="flex gap-2 items-center">
                  <b>Scope:</b> {selectedProject.scope}
                </span>
                <span className="flex gap-2 items-center">
                  <b>Area:</b> {selectedProject.area}m²
                </span> */}

                </table>
                <div className="h-[1px] w-full bg-black my-3" />
                <p className="italic text-left text-[16px]">"{selectedProject.content}"</p>
                {/* Render the star rating */}

              </section>
              <img src={selectedProject.img} className="w-[500px] object-cover  z-0 h-[50vh]" alt={selectedProject.title} />

              </div>

          </div>
        </div>
// <div className="z-50 w-full fixed flex flex-col md:flex-row h-[100vh] top-0 bg-black/50">
        //   <div className="bg-white h-[90vh] flex m-auto md:flex-row flex-col  w-[90%] h-[90%]  relative">
        //     {/* Modal Header */}
        //     <div className="z-20 flex md:w-1/3 items-center px-10 relative text-white uppercase">
        //       <button
        //         className="absolute top-4 right-5 z-50 text-[30px] font-bold hover:cursor-pointer hover:scale-110"
        //         onClick={() => setShowModal(false)}
        //       >
        //         &times;
        //       </button>
        //       <img src="/images/websiteBg.jpg" className="object-cover h-full z-10 absolute top-0 w-full left-0" alt="background" />
        //       <h1 className="z-20 relative font-semibold">{selectedProject.title}</h1>
        //     </div>


        //       <div className="w-full md:w-1/3 relative overflow-none flex justify-center ">
        //         <img src={selectedProject.img} className="w-full object-cover  absolute z-0 h-full" alt={selectedProject.title} />
        //         <div className="flex flex-col justify-center z-10 relative backdrop-blur-xl bg-white/50 py-3 px-2 rounded-xl m-auto">
        //           <img src="/images/BREEAM_logo.png" className="m-auto w-[100px] md:w-[200px]" />
        //           <h2>{selectedProject.rating}</h2>
        //           <div className="flex gap-1 w-min m-auto">
        //             {Array.from({ length: ratingsDictionary[selectedProject.rating] }, (_, index) => (
        //               <IoStarSharp key={index} className="text-yellow-500" />
        //             ))}
        //           </div>

        //         </div>
        //       </div>
        //       <div className=" md:w-1/3 justify-between flex py-5 px-5 flex-col gap-2 text-[10px] md:text-[25px]">
        //       <button
        //         className="absolute top-4 right-5 z-50 text-[30px] font-bold hover:cursor-pointer hover:scale-110"
        //         onClick={() => setShowModal(false)}
        //       >
        //         &times;
        //       </button>
        //         <div className="flex flex-col md:gap-5 text-[15px] gap-2">
        //         <div>
        //           <h1 className="text-[16px] text-left">Construction Client</h1>
        //           <div className="h-[1px] w-full bg-black"/>
        //           <span className="flex gap-2 items-center">
        //             <FaUser /> {selectedProject.client}
        //           </span>

        //         </div>
        //         <span className="flex gap-2 items-center">
        //           <FaLocationDot /> {selectedProject.location}
        //         </span>
        //         <span className="flex gap-2 items-center">
        //           <SlCalender className="font-bold" /> {selectedProject.date}
        //         </span>
        //         <span className="flex gap-2 items-center">
        //           <b>Scope:</b> {selectedProject.scope}
        //         </span>
        //         <span className="flex gap-2 items-center">
        //           <b>Area:</b> {selectedProject.area}m²
        //         </span>

        //         </div>
        //         {/* Render the star rating */}
        //         <p className="italic text-left text-[20px]">"{selectedProject.content}"</p>
        //       </div>
        //   </div>
        // </div>
      )}
    </>
  );
}

const SwiperBtn = () => {
  const swiper = useSwiper();

  return (
    <div className="swiper-btns w-full flex items-center justify-end gap-x-4 mt-10 pe-8">
      <button
        className="text-white bg-green transition duration-300 hover:bg-black hover:text-white text-[15px] w-[50px] h-[40px] flex items-center justify-center skew-x-[-10deg]"
        onClick={() => swiper.slideNext()}
      >
        <FaArrowLeftLong />
      </button>
      <button
        className="text-white bg-green transition duration-300 hover:bg-black hover:text-white text-[15px] w-[50px] h-[40px] flex items-center justify-center skew-x-[-10deg]"
        onClick={() => swiper.slidePrev()}
      >
        <FaArrowRightLong />
      </button>
    </div>
  );
};
