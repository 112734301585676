import axios from "axios";

// Create an Axios instance with a base URL
const api = axios.create({
  baseURL: 'https://netreach.app:3002'
});

/**
 * Sends contact information and a message to the server.
 *
 * @param {string} name - The contact's name.
 * @param {string} email - The contact's email.
 * @param {string} phone - The contact's phone number.
 * @param {string} message - The message from the contact.
 * @returns {Promise<Object>} The response data from the server.
 */
export async function sendContactRequest(name, company, email, phone, message) {
  try {
    const response = await api.post('/send', {
      name,
      company,
      email,
      phone,
      message
    });
    return response.data;
  } catch (error) {
    console.error('Error sending contact request:', error);
    // Optionally re-throw the error to be handled by the calling code
    throw error;
  }
}

export async function sendProjectRequest(name, company, email, phone, rating, size, scope, quote) {
    try {
        alert(quote)
      const response = await api.post('/project', {
        name,
        company,
        email,
        phone,
        rating,
        size,
        scope,
        quote
      });
      return response.data;
    } catch (error) {
      console.error('Error sending contact request:', error);
      // Optionally re-throw the error to be handled by the calling code
      throw error;
    }
  }
