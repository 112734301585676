import '../App.css';
import Landing from '../components/Landing';
import NavBar from '../components/NavBar';
import Projects from '../components/Projects';
import Clients from '../components/Clients';
import Qualifications from '../components/Qualifications';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Technology from '../components/Technology';
import Form from '../components/Form';
import Certifications from '../components/Certifications';

const Home = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    // alert(sectionId)
    if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }


  return (
    <div className="App">
      <NavBar onNavClick={scrollToSection}/>
      <Landing id="landing" onNavClick={scrollToSection}/>
      <Clients id="clients" />
      <Form/>
      <Certifications/>
      {/* <Technology/> */}
      <Qualifications id="qualifications"/>
      <Projects id="projects"/>
      <Contact id="contact"/>
      <Footer />
    </div>
  );
}

export default Home;
